import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import {
  serverEventsSuccess,
  serverEventsError,
} from "../../store/serverEvents/actions"

import { fetchJobById } from "../../store/jobList/getJobById/actions"

function SSEHandler() {
  const dispatch = useDispatch()
  const API_URL = process.env.REACT_APP_NEST_API

  const [snackPack, setSnackPack] = useState([])
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState(undefined)

  // Assuming state shape and reducer keys, adjust as needed'
  
  const { filename } = useSelector(state => state.JobById.data || 'El audio ')

  useEffect(() => {
    const eventSource = new EventSource(`${API_URL}/events/sse`)

    eventSource.onmessage = event => {
      const message = JSON.parse(event.data)
      dispatch(serverEventsSuccess(message))
      // We will fetch job details based on the job_id received
      if (message.job_id) {
        dispatch(fetchJobById(message.job_id))
      }
      // Add to snackPack
      setSnackPack(prev => [
        ...prev,
        {
          key: new Date().getTime(),
          message: `Audio procesado con exito. Favor actualizar el listado de transcripciones.`,
          severity: "success",
        },
      ])
    }

    eventSource.onerror = error => {
      dispatch(serverEventsError(error))
      setSnackPack(prev => [
        ...prev,
        {
          key: new Date().getTime(),
          message: `Error: ${error.message}`,
          severity: "error",
        },
      ])
      eventSource.close()
    }

    return () => {
      eventSource.close()
    }
  }, [dispatch, API_URL, filename]) // Including filename as a dependency

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set current message and show Snackbar
      setMessageInfo({ ...snackPack[0] })
      setSnackPack(prev => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && messageInfo && open) {
      // Close currently open Snackbar if there is a new one in the queue
      setOpen(false)
    }
  }, [snackPack, messageInfo, open])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  const handleExited = () => {
    setMessageInfo(undefined)
  }

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert
        onClose={handleClose}
        severity={messageInfo ? messageInfo.severity : "info"}
        sx={{ width: "100%" }}
      >
        {messageInfo ? messageInfo.message : undefined}
      </Alert>
    </Snackbar>
  )
}

export default SSEHandler
