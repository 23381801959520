import {
  FETCH_LEMUR_ANALYSIS,
  FETCH_LEMUR_ANALYSIS_SUCCESS,
  FETCH_LEMUR_ANALYSIS_ERROR,
} from "./actionTypes"

export const fetchLemurAnalysis = transcript_id => {
  return {
    type: FETCH_LEMUR_ANALYSIS,
    payload: transcript_id,
  }
}

export const fetchLemurAnalysisSuccess = data => {
  return {
    type: FETCH_LEMUR_ANALYSIS_SUCCESS,
    payload: data,
  }
}

export const fetchLemurAnalysisError = error => {
  return {
    type: FETCH_LEMUR_ANALYSIS_ERROR,
    payload: error,
  }
}
