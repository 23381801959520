import React from "react"
import ReactHtmlParser from "react-html-parser"
import { millisToTime } from "ui/assets/functions"
import AudioPlayer from "components/AudioPlayer"
import { Button, Skeleton, TextField } from "@mui/material"
import SimpleModal from "../../../components/Modal"
import { downloadTxtFile } from "ui/assets/functions"
import "./styles.css"
const JobResultsTemplate = ({
  file_path,
  fileInfo,
  phrase_result,
  handleItemStart,
  itemStart,
  isLoading,
  LemurResponse,
  handleSearchText,
  highlightText,
  searchText,
  matchCount,
}) => {
  console.log("🚀 ~ fileInfo:", fileInfo)
  return (
    <div className="page-content">
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="row">
                <div className="col-6">
                  <h4 className="page-title">RUT: {fileInfo?.rut_cliente} - CLIENTE: {fileInfo?.nombre_apellido_cliente}</h4>
                  <div className="row">
                    <div className="col-4">
                      <span>SEGURO: {fileInfo?.seguro}</span>
                    </div>
                    <div className="col-4">
                      <span>CAMPAÑA: {fileInfo?.campana}</span>
                    </div>
                    <div className="col-4">
                     <span>FECHA: {fileInfo?.fecha_venta}</span>
                    </div>

                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end gap-2">
                  {/* icono 1 icono 2 */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-7 rounded">
          <TextField
            variant="standard"
            label="Buscar en el texto"
            size="small"
            onChange={e => {
              handleSearchText(e.target.value)
            }}
            className="w-50 mb-3"
          />
        </div>

        <div className="row d-flex row-no-gutters justify-content-between">
          <div className="col-7">
            <div className="table-responsive table-overflow">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Inicio</th>
                    <th scope="col">Texto</th>
                    <th scope="col">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading
                    ? Array.from(new Array(5)).map((_, index) => (
                        <tr key={index}>
                          <td>
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td>
                            <Skeleton variant="text" width="100%" />
                          </td>
                          <td>
                            <Skeleton variant="text" width={50} />
                          </td>
                        </tr>
                      ))
                    : phrase_result?.map((item, index) => (
                        <tr key={index}>
                          <td>{millisToTime(item.start)}</td>
                          <td>
                            {ReactHtmlParser(
                              highlightText(item.phrase || "", searchText)
                            )}
                          </td>
                          <td>
                            <Button
                              variant="outlined"
                              onClick={() => handleItemStart(item.start)}
                            >
                              <i className="fas fa-volume-up"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
            <div className="row mt-4">
              <AudioPlayer src={file_path} start={itemStart} />
            </div>
          </div>

          <div className="col-5 rounded">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Resumen IA</h5>
                <p className="card-text">{LemurResponse}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobResultsTemplate
