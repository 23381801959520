import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  RESET_USER_FLAG,
} from "./actionTypes"

const initialState = {
  loading: false,
  data: [], // Inicializa el array de usuarios en `data`
  success: false,
  error: null,
  message: null,
}

const CreateUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        loading: true,
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload], // Agrega el nuevo usuario al array en `data`
        success: true,
        error: null,
      }
    case CREATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.payload?.data?.message || null,
      }
    case RESET_USER_FLAG:
      return {
        ...state,
        success: false,
        loading: false,
        error: null,
        message: null,
      }
    default:
      return state
  }
}

const GetAllUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload, // Inicializa el array de usuarios en `data`
        error: null,
      }
    case GET_ALL_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

const GetUserByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BY_ID:
      return {
        ...state,
        loading: true,
      }
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }
    case GET_USER_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

const DeleteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_USER:
      return {
        ...state,
        loading: true,
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        success: true,
        error: null,
      }
    case DELETE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export { CreateUserReducer, GetAllUsersReducer, DeleteUserReducer, GetUserByIdReducer }
