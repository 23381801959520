import {
    SERVER_EVENTS,
    SERVER_EVENTS_SUCCESS,
    SERVER_EVENTS_ERROR
} from "./actionTypes"

export const serverEvents = event => {
    return {
        type: SERVER_EVENTS,
        payload: event
    }
}

export const serverEventsSuccess = data => {
    return {
        type: SERVER_EVENTS_SUCCESS,
        payload: data
    }
}

export const serverEventsError = error => {
    return {
        type: SERVER_EVENTS_ERROR,
        payload: error
    }
}


