export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_ERROR = "CREATE_USER_ERROR"

export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR"

export const GET_USER_BY_ID = "GET_USER_BY_ID"
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS"
export const GET_USER_BY_ID_ERROR = "GET_USER_BY_ID_ERROR"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"

export const RESET_USER_FLAG = "RESET_USER_FLAG"

