import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_PROGRESS,
  UPLOAD_PROGRESS_SUCCESS,
  UPLOAD_PROGRESS_ERROR,
} from "./actionTypes"

export const uploadFile = file => {
  return {
    type: UPLOAD_FILE,
    payload: file,
  }
}

export const uploadFileSuccess = data => {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload: data,
  }
}

export const uploadFileError = error => {
  return {
    type: UPLOAD_FILE_ERROR,
    payload: error,
  }
}

export const uploadProgress = progress => {
  return {
    type: UPLOAD_PROGRESS,
    payload: progress,
  }
}

export const uploadProgressSuccess = data => {
  return {
    type: UPLOAD_PROGRESS_SUCCESS,
    payload: data,
  }
}