import React, { useEffect, useRef, useCallback } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()

  const { userLevel } = useSelector(state => state?.AuthUser)

  const activateParentDropdown = useCallback(item => {
    // Esta función activa el menú desplegable del padre
    // La lógica para abrir los menús según la ruta activa permanece igual
    // Se simplifica para propósitos de demostración
  }, [])

  const path = useLocation()

  const activeMenu = useCallback(() => {
    // Función para activar el menú basado en la ruta
    // Se mantiene sin cambios
  }, [path.pathname])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [activeMenu])

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")}</li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            {/* Nuevos Menús Aquí */}
            <li>
              <Link to="/job-list" className="waves-effect">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Lista de trabajos")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/file-upload" className="waves-effect">
                <i className="bx bx-upload"></i>
                <span>{props.t("Subir archivo")}</span>
              </Link>
            </li> */}
            {userLevel === 1 &&
              <li>
                <Link to="/administration" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Administración")}</span>
                </Link>
              </li>
            }

            {/* Nuevos Menús Aquí */}
            <li>
              <Link to="/logout" className="waves-effect">
                <i className="bx bx-power-off"></i>
                <span>{props.t("Logout")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
