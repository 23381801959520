import { call, put, takeEvery } from "redux-saga/effects"
import api from "../api" // Asegúrate de que la ruta es correcta.
import { FETCH_LEMUR_ANALYSIS } from "./actionTypes"
import { fetchLemurAnalysisSuccess, fetchLemurAnalysisError } from "./actions"

const BASE_URL = process.env.REACT_APP_LEMUR_API

function* fetchLemurAnalisys({ payload: transcript_id }) {
  try {
    const response = yield call(
      api.postBody,
      BASE_URL,
      "/generate/task",
      JSON.stringify({
        transcript_ids: [transcript_id],
        prompt:
          "You are a helpful coach. Provide an analysis of the transcripts and offer areas to improve with exact quotes. Include no preamble. Start with an overall summary then get into the examples with feedback and indicates the minute of the conversation. give me the analysis in spanish starting with: El analisis de la conversacion es el siguiente",
        final_model: "basic",
      })
    )
    yield put(fetchLemurAnalysisSuccess(response))
  } catch (error) {
    yield put(fetchLemurAnalysisError(error.toString()))
  }
}

function* fetchLemurAnalisysSaga() {
  yield takeEvery(FETCH_LEMUR_ANALYSIS, fetchLemurAnalisys)
}

export default fetchLemurAnalisysSaga
