import { takeEvery } from 'redux-saga/effects';
import { SERVER_EVENTS_SUCCESS, SERVER_EVENTS_ERROR } from './actionTypes';

function* handleServerEventSuccess(action) {
    console.log('SSE Success:', action.payload);
    // Aquí puedes poner más lógica para manejar los datos recibidos
}

function* handleServerEventError(action) {
    console.error('SSE Error:', action.payload);
    // Aquí puedes manejar el error, por ejemplo, actualizar el estado para mostrar un error en la UI
}

function* watchServerEvents() {
    yield takeEvery(SERVER_EVENTS_SUCCESS, handleServerEventSuccess);
    yield takeEvery(SERVER_EVENTS_ERROR, handleServerEventError);
}

export default watchServerEvents;
