import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_FILES_INFO, SEARCH_FILES_INFO } from "./actionTypes"
import {
  fetchFilesInfoSuccess,
  fetchFilesInfoError,
  searchFilesInfoSuccess,
  searchFilesInfoError,
} from "./actions"

import api from "../api"

const BASE_URL = process.env.REACT_APP_NEST_API

function* fetchFilesInfo(action) {
  console.log("🚀 ~ function*fetchFilesInfo ~ action:", action)
  const { payload: id } = action
  try {
    const response = yield call(api.get, BASE_URL, `/files-info/${id}`)
    yield put(fetchFilesInfoSuccess(response))
  } catch (error) {
    yield put(fetchFilesInfoError(error.toString()))
  }
}

function* fetchFilesInfoSaga() {
  yield takeEvery(FETCH_FILES_INFO, fetchFilesInfo)
}

function* searchFilesInfo({ payload: query }) {
  try {
    const response = yield call(api.post, BASE_URL, `/files-info/search`, {
      query,
    })
    yield put(searchFilesInfoSuccess(response))
  } catch (error) {
    yield put(searchFilesInfoError(error.toString()))
  }
}

function* searchFilesInfoSaga() {
  yield takeEvery(SEARCH_FILES_INFO, searchFilesInfo)
}

export {fetchFilesInfoSaga}
