import React from "react"
import { Box, Typography, Button, IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import CloseIcon from "@mui/icons-material/Close"

const DeleteModal = ({ handleOnClickDeleteUser, id, username }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
        position: "relative",
      }}
    >
      <DeleteIcon sx={{ fontSize: 60, color: "red", mb: 2 }} />
      <Typography variant="h6" component="h2">
        ¿Borrar el usuario: {username}?
      </Typography>
      <Typography sx={{ mt: 2 }}>Esta acción no se puede deshacer.</Typography>
      <Button
        variant="contained"
        color="error"
        sx={{ mt: 3 }}
        onClick={() => handleOnClickDeleteUser(id)}
      >
        Borrar
      </Button>
      
    </Box>
  )
}

export default DeleteModal
