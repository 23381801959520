import { call, put, takeLatest } from "redux-saga/effects"
import api from "../api"
import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_PROGRESS,
  UPLOAD_PROGRESS_SUCCESS,
  UPLOAD_PROGRESS_ERROR,
} from "./actionTypes"

import {
  uploadFileSuccess,
  uploadFileError,
  uploadProgress,
  uploadProgressError,
  uploadProgressSuccess,
} from "./actions"

const BASE_URL = process.env.REACT_APP_NEST_API

function* uploadFile(file) {
  try {
    const response = yield call(api.post, BASE_URL, `/upload/`, file.payload)
    yield put(uploadFileSuccess(response))
  } catch (error) {
    yield put(uploadFileError(error))
  }
}

export default function* uploadSaga() {
  yield takeLatest(UPLOAD_FILE, uploadFile)
}
