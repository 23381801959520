import { call, put, takeLatest } from "redux-saga/effects"
import api from "../../api"
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import {
  loginUserSuccess,
  loginUserError,
  logoutUserSuccess,
  logoutUserError,
} from "./actions"

const BASE_URL_API = process.env.REACT_APP_NEST_API

function* loginUser({ payload: { user } }) {
  try {
    const { username, password, navigate } = user // Extraer username y password
    const response = yield call(api.post, BASE_URL_API, "/auth/login", {
      username,
      password,
    })

    const { access_token, userId } = response

    if (userId && access_token) {
      localStorage.setItem("authUser", JSON.stringify({ access_token, userId }))
      const response = yield call(api.get, BASE_URL_API, `/users/${userId}`)
      const { name, lastName, userLevel } = response
      localStorage.setItem(
        "userData",
        JSON.stringify({ name, lastName, userLevel })
      )
      yield put(loginUserSuccess({ userLevel, access_token, userId }))
      navigate("/dashboard")
    } else {
      yield put(loginUserError("User ID missing in response"))
    }
  } catch (error) {
    yield put(loginUserError(error?.response?.data || "Login failed"))
  }
}

export function* LoginUserSaga() {
  yield takeLatest(LOGIN_USER, loginUser)
}

function* logoutUser({ payload: { navigate } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("userData")
    yield put(logoutUserSuccess())
    navigate("/login") // Usar navigate para redirección
  } catch (error) {
    yield put(logoutUserError(error))
  }
}

export function* LogoutUserSaga() {
  yield takeLatest(LOGOUT_USER, logoutUser)
}
