import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import CardStats from "../../../components/CardStats"
import Skeleton from "@mui/material/Skeleton"

const DashboardTemplate = ({
  cardsData,
  userName,
  grettings,
  isLoading,
  navigate,
}) => {
  return (
    (isLoading && (
      <div style={{ padding: "20px" }}>
        <h1>
          <Skeleton width={200} />
        </h1>
        <h2>
          <Skeleton width={300} />
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          {[...Array(4)].map((_, index) => (
            <div
              key={index}
              style={{
                width: "23%",
                padding: "20px",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
              }}
            >
              <Skeleton height={30} width={150} />
              <Skeleton height={50} width={100} style={{ marginTop: "10px" }} />
              <Skeleton height={20} width={80} style={{ marginTop: "10px" }} />
            </div>
          ))}
        </div>
      </div>
    )) || (
      <Container fluid>
        <Row className="align-items-center mb-4">
          <Col>
            <h2>Hola, {userName}</h2>
            <p>Que tengas {grettings}</p>
          </Col>
          <Col xs="auto">
            {/* <Button color="primary" onClick={() => navigate("/file-upload")}>
              + Subir un Audio
            </Button> */}
          </Col>
        </Row>
        <Row>
          {cardsData.map((card, index) => (
            <Col key={index} md="3">
              <CardStats {...card} />
            </Col>
          ))}
        </Row>
      </Container>
    )
  )
}

export default DashboardTemplate
