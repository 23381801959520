// Utilidades.js

// Función para convertir milisegundos a un formato de hora HH:MM:SS
const millisToTime = millis => {
  const secs = Math.floor(millis / 1000) // Convertir milisegundos a segundos
  const hours = Math.floor(secs / 3600) // 3600 segundos en una hora
  const minutes = Math.floor((secs % 3600) / 60) // Residuo de horas convertido a minutos
  const secRemains = secs % 60 // Residuo de minutos convertido a segundos

  // Formateando la salida para asegurar dos dígitos
  const strHours = hours.toString().padStart(2, "0")
  const strMinutes = minutes.toString().padStart(2, "0")
  const strSecs = secRemains.toString().padStart(2, "0")

  return `${strHours}:${strMinutes}:${strSecs}`
}

// Función para formatear fechas ISO a un formato legible
const formatISODate = isoDate => {
  const date = new Date(isoDate)

  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")

  const hours = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const seconds = date.getSeconds().toString().padStart(2, "0")

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

const downloadTxtFile = (text, filename) => {
  // Crear un Blob con los datos en un formato de texto plano
  const blob = new Blob([text], { type: "text/plain" })

  // Crear un enlace para la descarga
  const anchor = document.createElement("a")
  anchor.download = filename
  anchor.href = window.URL.createObjectURL(blob)
  anchor.style.display = "none" // No mostrar el enlace

  // Agregar el enlace al cuerpo del documento y hacer clic en él
  document.body.appendChild(anchor)
  anchor.click()

  // Remover el enlace después de la descarga
  document.body.removeChild(anchor)
}

// Exporta las funciones como un objeto para poder desestructurarlas al importar
export { millisToTime, formatISODate, downloadTxtFile }
