import {
  FETCH_TRANSCRIPTION,
  FETCH_TRANSCRIPTION_SUCCESS,
  FETCH_TRANSCRIPTION_ERROR,
} from "./actionTypes"

export const fetchTranscription = id => {
  return {
    type: FETCH_TRANSCRIPTION,
    payload: id,
  }
}

export const fetchTranscriptionSuccess = data => {
  return {
    type: FETCH_TRANSCRIPTION_SUCCESS,
    payload: data,
  }
}

export const fetchTranscriptionError = error => {
  return {
    type: FETCH_TRANSCRIPTION_ERROR,
    payload: error,
  }
}
