import { call, put, takeEvery } from "redux-saga/effects"
import api from "../api" // Asegúrate de que la ruta es correcta.
import {
  FETCH_TRANSCRIPTION,
} from "./actionTypes"
import { fetchTranscriptionSuccess, fetchTranscriptionError } from "./actions"

// La URL base de tu API
const BASE_URL = process.env.REACT_APP_NEST_API

function* fetchTranscription({ payload: id }) {
  try {
    const response = yield call(
      api.get,
      BASE_URL,
      `/transcription-result/${id}/` // job_id en este caso
    )
    yield put(fetchTranscriptionSuccess(response))
  } catch (error) {
    yield put(fetchTranscriptionError(error.toString()))
  }
}

function* fetchTranscriptionSaga() {
  yield takeEvery(FETCH_TRANSCRIPTION, fetchTranscription)
}

export default fetchTranscriptionSaga
