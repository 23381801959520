import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem("authUser"); // Verifica si el token JWT está en el localStorage

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default Authmiddleware;
