export const UPLOAD_FILE = "UPLOAD_FILE"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR"

export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS"
export const UPLOAD_PROGRESS_SUCCESS = "UPLOAD_PROGRESS_SUCCESS"
export const UPLOAD_PROGRESS_ERROR = "UPLOAD_PROGRESS_ERROR"

export const UPLOAD_STATUS = "UPLOAD_STATUS"
export const UPLOAD_STATUS_SUCCESS = "UPLOAD_STATUS_SUCCESS"
export const UPLOAD_STATUS_ERROR = "UPLOAD_STATUS_ERROR"

