import {
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_ERROR,
    GET_USER_BY_ID,
    GET_USER_BY_ID_SUCCESS,
    GET_USER_BY_ID_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    RESET_USER_FLAG,
} from "./actionTypes"

export const resetUserFlag = () => {
    return {
        type: RESET_USER_FLAG,
    }
}

export const createUser = user => {
    return {
        type: CREATE_USER,
        payload: { user },
    }
}

export const createUserSuccess = data => {
    return {
        type: CREATE_USER_SUCCESS,
        payload: data,
    }
}

export const createUserError = error => {
    return {
        type: CREATE_USER_ERROR,
        payload: error,
    }
}

export const getAllUsers = users => {
    return {
        type: GET_ALL_USERS,
    }
}

export const getAllUsersSuccess = data => {
    return {
        type: GET_ALL_USERS_SUCCESS,
        payload: data,
    }
}

export const getAllUsersError = error => {
    return {
        type: GET_ALL_USERS_ERROR,
        payload: error,
    }
}

export const getUserById = id => {
    return {
        type: GET_USER_BY_ID,
        payload: id,
    }
}

export const getUserByIdSuccess = data => {
    return {
        type: GET_USER_BY_ID_SUCCESS,
        payload: data,
    }
}

export const getUserByIdError = error => {
    return {
        type: GET_USER_BY_ID_ERROR,
        payload: error,
    }
}

export const deleteUser = data => {
    return {
        type: DELETE_USER,
        payload: data,
    }
}

export const deleteUserSuccess = id => {
    return {
        type: DELETE_USER_SUCCESS,
        payload: id,
    }
}

export const deleteUserError = error => {
    return {
        type: DELETE_USER_ERROR,
        payload: error,
    }
}



