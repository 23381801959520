import React from "react"
import Dropzone from "../../../components/DropZone"
import { Button } from "reactstrap"

const UploadTemplate = ({
  fileArray,
  setFileArray,
  // handleClickUpload,
  uploadMessage,
  uploadError,
  onUpload,
  isLoading,
}) => {
  const listItems = fileArray.map((file, index) => (
    <li className="list-group-item" key={index}>
      {file.name}
    </li>
  ))

  return (
    <div className="page-content">
      <div className="row justify-content-center">
        <div className="col-6 text-center">
          <h3 className="mb-3">Subir Archivos</h3>
          <Dropzone setFileArray={setFileArray} onUpload={onUpload} />
          <div className="row">
            <ul className="list-group">{listItems}</ul>
            <div className="row justify-content-center">
              {isLoading ? (
                <span className="spinner-border text-success" role="status">
                  <span className="sr-only">Subiendo Archivo, no salga de esta pantalla...</span>
                </span>
              ) : null}
            </div>
          </div>

          {uploadMessage.showMessage ? (
            <>
              {uploadError ? (
                <div className={`alert alert-danger mt-3`}>
                  {uploadMessage.msg}
                </div>
              ) : !uploadError ? (
                <div className={`alert alert-success mt-3`}>
                  {uploadMessage.msg}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default React.memo(UploadTemplate)
