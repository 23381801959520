import axios from "axios"

const headers = {
  "Content-Type": "application/json",
}

const LEMUR_TOKEN = process.env.REACT_APP_LEMUR_API_KEY

const api = {
  get: async (base_url, endpoint) => {
    try {
      const response = await axios.get(`${base_url}${endpoint}`, { headers })
      return response.data
    } catch (error) {
      console.error("Error:", error)
      throw error
    }
  },

  post: async (base_url, endpoint, formData) => {
    console.log("🚀 ~ post: ~ formData:", formData)
    try {
      const response = await axios.post(`${base_url}${endpoint}`, formData)
      return response.data
    } catch (error) {
      console.error("Error:", error)
      throw error
    }
  },

  postBody: async (base_url, endpoint, body) => {

    // Configura los encabezados por defecto y agrega cualquier encabezado adicional proporcionado
    const config = {
      headers: {
        "Content-Type": "application/json", // Asegura que el contenido se trate como JSON
        Authorization: LEMUR_TOKEN,
      },
    }

    try {
      const response = await axios.post(`${base_url}${endpoint}`, body, config)
      return response.data
    } catch (error) {
      console.error("Error al enviar el body:", error)
      throw error
    }
  },

  put: async (base_url, endpoint, body) => {
    try {
      const response = await axios.put(`${base_url}${endpoint}`, body, {
        headers,
      })
      return response.data
    } catch (error) {
      console.error("Error:", error)
      throw error
    }
  },

  delete: async (base_url, endpoint) => {
    try {
      const response = await axios.delete(`${base_url}${endpoint}`, { headers })
      return response.data
    } catch (error) {
      console.error("Error:", error)
      throw error
    }
  },
}

export default api
