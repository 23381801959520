import {
  FETCH_FILES_INFO,
  FETCH_FILES_INFO_SUCCESS,
  FETCH_FILES_INFO_ERROR,
  SEARCH_FILES_INFO,
  SEARCH_FILES_INFO_SUCCESS,
  SEARCH_FILES_INFO_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  data: {},
  error: null,
}

const filesInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FILES_INFO:
      return {
        ...state,
        loading: true,
      }
    case FETCH_FILES_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }
    case FETCH_FILES_INFO_ERROR:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      }
    default:
      return state
  }
}

const searchFilesInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_FILES_INFO:
      return {
        ...state,
        loading: true,
      }
    case SEARCH_FILES_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }
    case SEARCH_FILES_INFO_ERROR:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      }
    default:
      return state
  }
}

export { filesInfoReducer, searchFilesInfoReducer }
