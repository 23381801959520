import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import { Typography, Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const CardStats = ({ title, value, trend, percentage, trendDirection }) => {
  return (
    <Card className="mb-4">
      <CardBody>
        <CardTitle tag="h5">{title}</CardTitle>
        <Typography variant="h3">{value}</Typography>
        <Box display="flex" alignItems="center" className={trendDirection === 'up' ? 'text-success' : 'text-danger'}>
          {trendDirection === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          <CardText className="ml-2">{percentage}%</CardText>
        </Box>
        {/* <CardSubtitle>{trendDirection === 'up' ? 'Increase' : 'Decrease'} last month</CardSubtitle> */}
      </CardBody>
    </Card>
  );
};

export default CardStats;
