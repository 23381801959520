import {
  DELETE_JOB_BY_ID,
  DELETE_JOB_BY_ID_SUCCESS,
  DELETE_JOB_BY_ID_ERROR,
} from "./actionTypes"

export const deleteJobById = id => {
  return {
    type: DELETE_JOB_BY_ID,
    payload: id,
  }
}

export const deleteJobByIdSuccess = data => {
  return {
    type: DELETE_JOB_BY_ID_SUCCESS,
    payload: data,
  }
}

export const deleteJobByIdError = error => {
  return {
    type: DELETE_JOB_BY_ID_ERROR,
    payload: error,
  }
}
