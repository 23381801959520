import {
    SERVER_EVENTS,
    SERVER_EVENTS_SUCCESS,
    SERVER_EVENTS_ERROR
} from "./actionTypes"

const initialState = {
    loading: false,
    data: null,
    error: null
}


const serverEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SERVER_EVENTS:
            return {
                ...state,
                loading: true
            }
        case SERVER_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case SERVER_EVENTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default serverEventsReducer