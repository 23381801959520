import React from "react"
import {
  Modal,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const SimpleModal = ({
  open,
  setOpen,
  children,
  minWidth = 300,
  maxWidth = 600,
  top = "50%",
  title = "",
  isLoading, // Añadir esta línea para recibir la prop isLoading
}) => {
  const handleClose = () => setOpen(false)

  const style = {
    position: "absolute",
    top: top,
    left: "55%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid grey",
    borderRadius: 5,
    boxShadow: 24,
    p: 3,
    minWidth: minWidth,
    maxWidth: maxWidth,
    width: "auto",
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box sx={style}>
        {isLoading ? (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              borderRadius: 5,
              justifyContent: "center",
              backgroundColor: "rgb(216, 216, 216)",
            }}
          >
            <CircularProgress />
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            alignItems: "center",
            borderBottom: title ? "1px solid #e0e0e0" : "none",
          }}
        >
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", right: 8 }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <div className="row justify-content-center mt-3">{children}</div>
      </Box>
    </Modal>
  )
}

export default SimpleModal
