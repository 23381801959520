import React, { useEffect, useRef } from "react"
import JobResultsContainer from "ui/container/JobResults"

const JobResults = () => {
  
  return <JobResultsContainer />

}

export default JobResults
