import PropTypes from "prop-types"
import React from "react"
import { Container } from "reactstrap"
import DashboardContainer from "ui/container/Dashboard"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | Transcribe v1.0.0"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            BreadcrumbItem={props.t("Dashboard")}
          />

          <DashboardContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
