import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { uploadFile } from "store/upload/actions"
import UploadTemplate from "ui/templates/Upload"

const UploadContainer = () => {
  const dispatch = useDispatch()
  const [fileArray, setFileArray] = useState([])
  const [uploadMessage, setUploadMessage] = useState({
    showMessage: false,
    msg: "",
    type: "",
  })

  const {
    data,
    loading: isLoading,
    error: uploadError,
  } = useSelector(state => state?.Upload)

  const handleUpload = useCallback(
    formData => {
      // Aquí despachas la acción con el FormData
      dispatch(uploadFile(formData))
    },
    [dispatch]
  )
  // useEffect para borrar el mensaje de error cuando fileArray cambia
  useEffect(() => {
    if (fileArray.length > 0) {
      setUploadMessage({
        showMessage: false,
        msg: "",
        type: "",
      })
    }
  }, [fileArray]) // Dependencia en fileArray

  useEffect(() => {
    if (data) {
      setUploadMessage({
        showMessage: true,
        msg: "Archivo recibido, revisar estado en lista de trabajos",
        type: "success",
      })
    }

    if (uploadError) {
      setUploadMessage({
        showMessage: true,
        msg: "Error en la recepcion del archivo, reintente.",
        type: "danger",
      })
    }
  }, [data, uploadError])

  // const handleClickUpload = () => {
  //   if (fileArray.length === 0) {
  //     setUploadMessage({
  //       error: true,
  //       msg: "Please upload a file",
  //       type: "danger",
  //     })
  //   } else {
  //     // Procesa la carga de archivos aquí, si es necesario
  //     setUploadMessage({
  //       error: false,
  //       msg: "File uploaded successfully",
  //       type: "success",
  //     })
  //   }
  // }

  return (
    <UploadTemplate
      setFileArray={setFileArray}
      fileArray={fileArray}
      uploadMessage={uploadMessage}
      uploadError={uploadError}
      onUpload={handleUpload}
      isLoading={isLoading}
      fileUploadData={data}
    />
  )
}

export default UploadContainer
