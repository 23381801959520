import {
  FETCH_JOB_BY_ID,
  FETCH_JOB_BY_ID_SUCCESS,
  FETCH_JOB_BY_ID_ERROR,
} from "./actionTypes"

export const fetchJobById = id => {
  return {
    type: FETCH_JOB_BY_ID,
    payload: id,
  }
}

export const fetchJobByIdSuccess = data => {
  return {
    type: FETCH_JOB_BY_ID_SUCCESS,
    payload: data,
  }
}

export const fetchJobByIdError = error => {
  return {
    type: FETCH_JOB_BY_ID_ERROR,
    payload: error,
  }
}