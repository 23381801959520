import React from "react"
import { Container, Row, Table } from "reactstrap"
import { TextField, InputAdornment } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import Chip from "@mui/material/Chip"
import Button from "@mui/material/Button"
import Skeleton from "@mui/material/Skeleton"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import Pagination from "@mui/material/Pagination" // Importar el componente de paginación
import "./styles.css"

const JobListTemplate = ({
  jobs,
  isLoading,
  onInputSearchChange,
  onSearch,
  handleOnClickViewJob,
  handleOnClickDeleteJob,
  handleUpdateJobList,
  totalPages,
  currentPage,
  onPageChange,
}) => {
  console.log("🚀 ~ jobs:", jobs)
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Lista de trabajos" />
        <div className="row">
          <div className="col-6 mb-4">
            <form
              noValidate
              autoComplete="off"
              onSubmit={e => e.preventDefault()}
            >
              <TextField
                id="search"
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => onInputSearchChange("rut", e.target.value)}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    onSearch()
                  }
                }}
              />
              <Button onClick={onSearch}>Buscar por Rut</Button>
              <Button onClick={handleUpdateJobList}>Borrar Búsqueda</Button>
            </form>
          </div>
        </div>
        <Row>
          <div className="col-lg-12">
            <div className="card p-3">
              <div className="table-wrapper">
                <Table responsive>
                  <thead className="sticky-header text-center">
                    <tr>
                      <th style={{ width: "20%", textAlign: "left" }}>
                        Rut Cliente
                      </th>
                      <th style={{ width: "20%", textAlign: "left" }}>
                        Seguro
                      </th>
                      <th style={{ width: "20%", textAlign: "left" }}>Campana</th>
                      <th>Fecha Venta</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <tr key={index}>
                            <td>
                              <Skeleton variant="text" width="100%" />
                            </td>
                            <td>
                              <Skeleton variant="text" width="100%" />
                            </td>
                            <td>
                              <Skeleton variant="text" width="100%" />
                            </td>
                            <td>
                              <Skeleton variant="text" width="80%" />
                              <Skeleton
                                variant="text"
                                width="80%"
                                style={{ marginLeft: 8 }}
                              />
                            </td>
                          </tr>
                        ))
                      : jobs?.map((job, index) => (
                          <tr key={index}>
                            <td>{job.rut_cliente}</td>
                            <td>{job.seguro}</td>
                            <td>{job.campana}</td>
                            {/* <td className="text-center">
                              <Chip
                                label={
                                  job?.status === "completed"
                                    ? "Finalizado"
                                    : "Procesando"
                                }
                                color={
                                  job?.status === "completed"
                                    ? "success"
                                    : "warning"
                                }
                                variant="outlined"
                              />
                            </td> */}
                            <td className="text-center">{job?.fecha_venta}</td>
                            <td className="text-center">
                              <Button
                                size="small"
                                color="primary"
                                className="mx-2"
                                onClick={() => handleOnClickViewJob(job.job_id)}
                                disabled={job?.status !== "completed"}
                                variant="outlined"
                              >
                                Ver
                              </Button>
                              {/* <Button
                                size="small"
                                color="error"
                                className="mx-2"
                                onClick={() =>
                                  handleOnClickDeleteJob(job.job_id)
                                }
                                variant="outlined"
                              >
                                Eliminar
                              </Button> */}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
              <div className="row mt-4">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => onPageChange(value)}
                  color="primary"
                  className="pagination"
                />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default JobListTemplate
