import {
  FETCH_JOB_LIST_VIEW,
  FETCH_JOB_LIST_VIEW_SUCCESS,
  FETCH_JOB_LIST_VIEW_ERROR,
  SEARCH_JOB_BY_RUT,
  SEARCH_JOB_BY_RUT_SUCCESS,
  SEARCH_JOB_BY_RUT_ERROR,
} from "./actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
  totalPages: 1,
  total: 0,
  page: 1,
  limit: 10,
};

const jobListViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_LIST_VIEW:
    case SEARCH_JOB_BY_RUT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_JOB_LIST_VIEW_SUCCESS:
    case SEARCH_JOB_BY_RUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        totalPages: action.payload.totalPages,
        total: action.payload.total,
        page: action.payload.page,
        limit: action.payload.limit,
      };
    case FETCH_JOB_LIST_VIEW_ERROR:
    case SEARCH_JOB_BY_RUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { jobListViewReducer };
