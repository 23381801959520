import {
  FETCH_TRANSCRIPTION,
  FETCH_TRANSCRIPTION_SUCCESS,
  FETCH_TRANSCRIPTION_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  data: null,
  error: null,
}

const transcriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSCRIPTION:
      return {
        ...state,
        loading: true,
      }
    case FETCH_TRANSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }
    case FETCH_TRANSCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default transcriptionReducer
