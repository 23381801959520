import { call, put, takeEvery } from "redux-saga/effects"
import { get } from "../../api" // Importa la función get desde api.js
import {
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_ERROR,
  SET_LOADING,
  CLEAR_LOADING,
  FETCH_JOBS,
} from "./actionTypes"
import api from "../../api"

// La URL base de tu API
const BASE_URL = process.env.REACT_APP_NEST_API

// La saga que maneja la acción FETCH_JOBS
function* fetchJobsSaga() {
  try {
    yield put({ type: SET_LOADING })
    const data = yield call(api.get, BASE_URL, "/job-list") // Realiza la petición GET al endpoint /job-list
    yield put({ type: FETCH_JOBS_SUCCESS, payload: data }) // En caso de éxito, dispara la acción FETCH_JOBS_SUCCESS
  } catch (error) {
    yield put({ type: FETCH_JOBS_ERROR, payload: error.toString() }) // En caso de error, dispara la acción FETCH_JOBS_ERROR
  } finally {
    yield put({ type: CLEAR_LOADING }) // Finalmente, limpia el estado de carga
  }
}

function* fetchAllJobsSaga() {
  yield takeEvery(FETCH_JOBS, fetchJobsSaga)
}

export default fetchAllJobsSaga
