import { call, put, takeEvery } from "redux-saga/effects"
import api from "../../api" // Asegúrate de que la ruta es correcta.
import {
  DELETE_JOB_BY_ID
} from "./actionTypes"
import { deleteJobByIdSuccess, deleteJobByIdError } from "./actions"

// La URL base de tu API
const BASE_URL = process.env.REACT_APP_NEST_API

function* deleteJob({ payload: id }) {
  try {
    const response = yield call(api.delete, BASE_URL, `/job-list/${id}`)
    yield put(deleteJobByIdSuccess(response))
  } catch (error) {
    yield put(deleteJobByIdError(error.toString()))
  }
}

function* deleteJobSaga() {
  yield takeEvery(DELETE_JOB_BY_ID, deleteJob)
}

export default deleteJobSaga
