import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import api from "../api" // Asegúrate de que la ruta es correcta.
import { CREATE_USER } from "./actionTypes"
import {
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from "./actionTypes"
import { createUserSuccess, createUserError } from "./actions"

const BASE_URL_API = process.env.REACT_APP_NEST_API

function* createUser({ payload: { user } }) {
  try {
    const response = yield call(api.post, BASE_URL_API, "/users", user)
    yield put(createUserSuccess(response.data))
  } catch (error) {
    yield put(createUserError(error.response))
  }
}

export default function* createUserSaga() {
  yield takeLatest(CREATE_USER, createUser)
}

function* getAllUsers() {
  try {
    const response = yield call(api.get, BASE_URL_API, "/users")
    yield put({ type: GET_ALL_USERS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_ALL_USERS_ERROR, payload: error.message })
  }
}

export function* getAllUsersSaga() {
  yield takeLatest(GET_ALL_USERS, getAllUsers)
}

function* getUserById({ payload: id }) {
  try {
    const response = yield call(api.get, BASE_URL_API, `/users/${id}`)
    yield put({ type: GET_USER_BY_ID_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_USER_BY_ID_ERROR, payload: error.message })
  }
}

export function* getUserByIdSaga() {
  yield takeLatest(GET_USER_BY_ID, getUserById)
}

function* deleteUser({ payload: id }) {
  try {
    const response = yield call(api.delete, BASE_URL_API, `/users/${id}`)
    yield put({ type: DELETE_USER_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: DELETE_USER_ERROR, payload: error.message })
  }
}

export function* deleteUserSaga() {
  yield takeLatest(DELETE_USER, deleteUser)
}
