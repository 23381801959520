import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import cloudUpload from "../../ui/assets/gif/cloud-upload.gif" // Asegúrate de tener este archivo o cambia la ruta

// Estilos personalizados para el dropzone
const dropzoneStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  border: "2px dashed #007bff",
  borderRadius: "0.25rem",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
  backgroundColor: "#fff",
}

const Dropzone = ({ setFileArray, onUpload }) => {
  
  const [errorMessage, setErrorMessage] = useState("")

  const onDrop = useCallback(
    acceptedFiles => {
      setErrorMessage("") // Limpiar mensajes de error anteriores
      setFileArray(acceptedFiles)

      const formData = new FormData()
      acceptedFiles.forEach(file => {
        formData.append("file", file)
      })

      if (onUpload) {
        onUpload(formData)
      }
    },
    [onUpload, setFileArray]
  )

  const onDropRejected = useCallback(fileRejections => {
    // Solo mostramos el mensaje de error del primer archivo rechazado
    const message = fileRejections[0]?.errors[0]?.message
    setErrorMessage(`Error: ${message}`)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // onDropRejected,
    // accept: 'audio/mpeg, audio/wav, audio/x-wav',
  })

  return (
    <div {...getRootProps()} style={dropzoneStyle}>
      <input {...getInputProps()} />
      <img src={cloudUpload} alt="Upload" style={{ width: "100px" }} />
      {isDragActive ? (
        <p>Suelta los archivos aquí ...</p>
      ) : (
        <p>
          Arrastra y suelta algunos archivos aquí, o haz clic para seleccionar
          archivos
        </p>
      )}
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  )
}

export default Dropzone
