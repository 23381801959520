
import {
  FETCH_JOB_BY_ID,
  FETCH_JOB_BY_ID_SUCCESS,
  FETCH_JOB_BY_ID_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  data: {},
  error: null
}


const getJobByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_BY_ID:
      return {
        ...state,
        loading: true,
      }
    case FETCH_JOB_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false, // Asegúrate de desactivar la carga tras recibir los datos
        data: action.payload,
        error: null
      };
    case FETCH_JOB_BY_ID_ERROR:
      return {
        ...state,
        loading: false, // Asegúrate de desactivar la carga tras un error
        job: {}, // Opcional: limpiar los trabajos previos al recibir un error
        error: action.payload
      };
    default:
      return state;
  }
}

export default getJobByIdReducer