import React from "react"
import { Container, Row, Col } from "reactstrap"
import SimpleModal from "../../../components/Modal"
import UserDataModal from "./UserDataModal"
import TableContainer from "components/Common/TableContainer"
import KeyIcon from "@mui/icons-material/Key"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"

import { Button } from "@mui/material"
import DeleteModal from "./DeleteModal"
import ChangePasswdModal from "./ChangePasswdModal"

const AdministrationTemplate = ({
  handleOnChangeForm,
  handleOnClickAddUser,
  handleOnClickDeleteUser,
  handleOpenModal,
  userData,
  userList,
  openModal,
  setOpenModal,
  modalType,
  createUserErrorMessages,
  createUserLoading,
}) => {
  const columns = [
    { header: "Usuario", accessorKey: "userName" },
    { header: `Nombre`, accessorKey: "fullName" },
    { header: "Rol", accessorKey: "role" },
    {
      header: "Acciones",
      accessorKey: "actions",
      cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <IconButton
            aria-label="edit"
            onClick={() => handleOpenModal("changePassword", row.original.id)}
          >
            <KeyIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleOpenModal("delete", row.original.id)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">Administración</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="d-flex justify-content-end mb-3">
            <Button variant="contained" onClick={() => handleOpenModal("add")}>
              Crear usuario
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableContainer
              columns={columns}
              data={userList}
              divClassName="table-responsive table-container table-overflow mb-0"
            />
          </Col>
        </Row>
        <SimpleModal
          open={openModal}
          setOpen={setOpenModal}
          isLoading={createUserLoading}
          title={modalType.title}
        >
          {modalType.name === "add" && (
            <UserDataModal
              id={modalType.id}
              handleOnChangeForm={handleOnChangeForm}
              handleOnClickAddUser={handleOnClickAddUser}
              userData={userData}
              errorMessages={createUserErrorMessages}
            />
          )}
          {modalType.name === "delete" && (
            <DeleteModal
              handleOnClickDeleteUser={handleOnClickDeleteUser}
              id={modalType.id}
              username={
                userList.find(user => user.id === modalType.id)?.userName
              }
            />
          )}
          {/* TODO: dispatch edit passwd */}
          {modalType.name === "changePassword" && (
            <ChangePasswdModal handleClose={() => setOpenModal(false)} id={modalType.id}/>
          )}
        </SimpleModal>
      </Container>
    </div>
  )
}

export default AdministrationTemplate
