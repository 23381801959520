import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JobListTemplate from "ui/templates/JobList";
import { deleteJobById } from "../../../store/jobList/deleteJobById/actions";
import { fetchJobListView, searchJobByRut } from "store/actions";
import { useNavigate } from "react-router-dom";

const JobListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState({
    rut: "",
  });

  const { data: jobs, isLoading, totalPages, total, page, limit } = useSelector((state) => state?.Views || {});
  const { error: deleteError } = useSelector((state) => state?.DeleteJob);

  useEffect(() => {
    // dispatch(fetchJobListView(1, 10)); // Llamada inicial solo una vez al montar el componente
  }, [dispatch]);

  useEffect(() => {
    if (page && limit) {
      dispatch(fetchJobListView(page, limit)); // Manejar cambios en la paginación
    }
  }, [dispatch, page, limit]);

  const handleInputSearchChange = (key, value) => {
    setSearchParams({
      ...searchParams,
      [key]: value,
    });
  };

  const handleOnClickViewJob = (id) => {
    navigate(`/job-results/${id}`);
  };

  const handleOnClickDeleteJob = (id) => {
    dispatch(deleteJobById(id));
  };

  const handleUpdateJobList = () => {
    dispatch(fetchJobListView(1, limit));
  };

  const handleSearch = () => {
    dispatch(searchJobByRut(searchParams.rut, 1, limit));
  };

  const handlePageChange = (newPage) => {
    dispatch(fetchJobListView(newPage, limit));
  };

  return (
    <JobListTemplate
      jobs={jobs}
      isLoading={isLoading}
      onInputSearchChange={handleInputSearchChange}
      onSearch={handleSearch}
      handleOnClickViewJob={handleOnClickViewJob}
      handleOnClickDeleteJob={handleOnClickDeleteJob}
      handleUpdateJobList={handleUpdateJobList}
      totalPages={totalPages}
      currentPage={page}
      onPageChange={handlePageChange}
    />
  );
};

export default JobListContainer;
