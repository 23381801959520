import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchJobById } from "store/actions"
import { fetchTranscription } from "store/transcription/actions"
import { fetchFilesInfo } from "store/actions"
import JobResultsTemplate from "ui/templates/JobResults"

const JobResultsContainer = () => {
  const id = useParams()?.id
  const dispatch = useDispatch()
  const [itemStart, setItemStart] = useState(null)
  const [searchText, setSearchText] = useState("")

  const jobData = useSelector(state => state?.JobById?.data || {})
  const fileInfo = useSelector(state => state?.FileInfo?.data || {})
  
  const transcriptionData = useSelector(
    state => state?.Transcription?.data || {}
  )

  const handleItemStart = itemStart => {
    setItemStart(itemStart)
  }

  const handleSearchText = e => {
    setSearchText(e)
  }

  const highlightText = (text, searchTerm) => {
    if (!searchTerm.trim()) {
      return text 
    }
    const regex = new RegExp(`(${searchTerm})`, "gi") 
    return text.replace(
      regex,
      '<span style="background-color: yellow;">$1</span>'
    )
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchJobById(id))
      dispatch(fetchTranscription(id))
      dispatch(fetchFilesInfo(id))
    }
  }, [id, dispatch])

  return (
    <JobResultsTemplate
      // filename={jobData.filename}
      fileInfo = {fileInfo}
      file_path={jobData.file_path}
      phrase_result={transcriptionData.phrase_result}
      itemStart={itemStart}
      handleItemStart={handleItemStart}
      isLoading={transcriptionData.loading}
      LemurResponse={transcriptionData.analized_text}
      full_text={transcriptionData.full_text}
      handleSearchText={handleSearchText}
      highlightText={highlightText}
      searchText={searchText}
    />
  )
}

export default JobResultsContainer
