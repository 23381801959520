import {
  DELETE_JOB_BY_ID,
  DELETE_JOB_BY_ID_SUCCESS,
  DELETE_JOB_BY_ID_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  deletedJob: {},
  error: null,
}

const deleteJobByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_JOB_BY_ID:
      return {
        ...state,
        loading: true,
      }
    case DELETE_JOB_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedJob: action.payload,
      }
    case DELETE_JOB_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default deleteJobByIdReducer
