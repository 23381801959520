import React, { useState } from "react"
import { TextField, Button, Box, FormHelperText } from "@mui/material"

const ChangePasswdModal = ({ handleChangePassword }) => {

  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [error, setError] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    if (newPassword === confirmNewPassword) {
      handleChangePassword(currentPassword, newPassword)
    } else {
      setError(true)
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <TextField
        margin="normal"
        error={error}
        required
        fullWidth
        label="Nuevo Password"
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
      />
      <FormHelperText>{error ? "Las passwords no coinciden" : null}</FormHelperText>
      <TextField
        margin="normal"
        error={error}
        required
        fullWidth
        label="Confirmar Password"
        value={confirmNewPassword}
        onChange={e => setConfirmNewPassword(e.target.value)}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Cambiar Password
      </Button>
    </Box>
  )
}

export default ChangePasswdModal
