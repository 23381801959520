import {
    FETCH_JOBS,
    FETCH_JOBS_SUCCESS,
    FETCH_JOBS_ERROR,
    SET_LOADING,
    CLEAR_LOADING
  } from './actionTypes';
  
  const initialState = {
    loading: false,
    jobs: [],
    error: null
  };
  
  const getAllJobsReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_LOADING:
        return {
          ...state,
          loading: true,
          error: null // Opcional: limpiar errores previos al iniciar una nueva carga
        };
      case CLEAR_LOADING:
        return {
          ...state,
          loading: false
        };
      case FETCH_JOBS_SUCCESS:
        return {
          ...state,
          loading: false, // Asegúrate de desactivar la carga tras recibir los datos
          jobs: action.payload,
          error: null
        };
      case FETCH_JOBS_ERROR:
        return {
          ...state,
          loading: false, // Asegúrate de desactivar la carga tras un error
          jobs: [], // Opcional: limpiar los trabajos previos al recibir un error
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getAllJobsReducer;
  