import { call, put, takeLatest } from "redux-saga/effects"
import { FETCH_JOB_LIST_VIEW, SEARCH_JOB_BY_RUT } from "./actionTypes"
import api from "../api"
import {
  fetchJobListViewSuccess,
  fetchJobListViewError,
  searchJobByRutSuccess,
  searchJobByRutError,
} from "./actions"

const BASE_URL = process.env.REACT_APP_NEST_API

function* fetchJobList(action) {
  try {
    const { page, limit } = action.payload
    const response = yield call(
      api.get,
      BASE_URL,
      `/view/table-info?page=${page}&limit=${limit}`
    )
    yield put(
      fetchJobListViewSuccess({
        data: response.data,
        totalPages: response.totalPages,
        total: response.total,
        page: response.page,
        limit: response.limit,
      })
    )
  } catch (error) {
    yield put(fetchJobListViewError(error.toString()))
  }
}

function* searchJobByRut(action) {
  try {
    const { rut, page, limit } = action.payload
    const response = yield call(
      api.get,
      BASE_URL,
      `/view/table-info/rut/${rut}?page=${page}&limit=${limit}`
    )
    yield put(
      searchJobByRutSuccess({
        data: response.data,
        totalPages: response.totalPages,
        total: response.total,
        page: response.page,
        limit: response.limit,
      })
    )
  } catch (error) {
    yield put(searchJobByRutError(error.toString()))
  }
}

function* FetchJobListViewSaga() {
  yield takeLatest(FETCH_JOB_LIST_VIEW, fetchJobList)
  yield takeLatest(SEARCH_JOB_BY_RUT, searchJobByRut)
}

export { FetchJobListViewSaga }
