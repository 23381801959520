import React, { useEffect, useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';

const AudioPlayer = ({ src, start }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    // Asegúrate de que el componente y la propiedad audioEl de la referencia estén definidos
    if (audioRef.current && audioRef.current.audioEl && start !== undefined) {
      const startTime = start / 1000; // Convertir milisegundos a segundos
      // Acceder al elemento de audio actual y establecer el tiempo de inicio
      audioRef.current.audioEl.current.currentTime = startTime;
      // Intentar reproducir el audio
      audioRef.current.audioEl.current.play().catch(error => console.error("Error al intentar reproducir el audio:", error));
    }
  }, [start]); // Este efecto se ejecuta cuando `start` cambia

  return (
    <ReactAudioPlayer
      src={src}
      ref={audioRef} // Pasar la referencia al reproductor
      controls
      autoPlay = {false}
    />
  );
};

export default AudioPlayer;
