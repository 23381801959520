import React from "react"
import {
  Button,
  TextField,
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material"

const UserDataModal = ({
  handleOnChangeForm,
  handleOnClickAddUser,
  handleOnClickEditUser,
  userData,
  errorMessages,
  id,
  isEditMode,
}) => {
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1 },
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "center",
      }}
      noValidate
      autoComplete="off"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FormControl sx={{ width: "48%" }} variant="outlined">
          <TextField
            required
            name="userName"
            label="Usuario"
            variant="outlined"
            size="small"
            value={userData.userName}
            onChange={handleOnChangeForm}
          />
        </FormControl>
        <FormControl sx={{ width: "48%" }} variant="outlined">
          <TextField
            required
            label="Contraseña"
            name="password"
            variant="outlined"
            size="small"
            value={userData.password}
            onChange={handleOnChangeForm}
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FormControl sx={{ width: "48%" }} variant="outlined">
          <TextField
            required
            label="Nombre"
            name="name"
            variant="outlined"
            size="small"
            value={userData.name}
            onChange={handleOnChangeForm}
          />
        </FormControl>
        <FormControl sx={{ width: "48%" }} variant="outlined">
          <TextField
            required
            label="Apellidos"
            name="lastName"
            variant="outlined"
            size="small"
            value={userData.lastName}
            onChange={handleOnChangeForm}
          />
        </FormControl>
      </Box>
      <FormControl sx={{ width: "100%" }} variant="outlined">
        <TextField
          required
          label="Email"
          name="email"
          variant="outlined"
          size="small"
          value={userData.email}
          onChange={handleOnChangeForm}
        />
      </FormControl>
      <FormControl sx={{ width: "47%", m: 1 }} variant="outlined">
        <InputLabel id="userLevel-label">Nivel de Usuario</InputLabel>
        <Select
          labelId="userLevel-label"
          id="userLevel"
          name="userLevel"
          value={userData.userLevel}
          label="Nivel de Usuario"
          onChange={handleOnChangeForm}
        >
          <MenuItem value={1}>Administrador</MenuItem>
          <MenuItem value={2}>Supervisor</MenuItem>
          <MenuItem value={3}>Analista</MenuItem>
          <MenuItem value={4}>Coach</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color={isEditMode ? "warning" : "success"}
          onClick={() => {
            isEditMode
              ? handleOnClickEditUser(id, userData)
              : handleOnClickAddUser(userData)
          }}
        >
          {isEditMode ? "Actualizar Usuario" : "Crear Usuario"}
        </Button>
        <Box sx={{ mt: 2 }}>
          {Array.isArray(errorMessages) ? (
            <ul>
              {errorMessages.map((errorMessage, index) => (
                <li key={index} style={{ color: "red" }}>
                  {errorMessage}
                </li>
              ))}
            </ul>
          ) : (
            errorMessages && (
              <ul>
                <li style={{ color: "red" }}>{errorMessages}</li>
              </ul>
            )
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default UserDataModal
