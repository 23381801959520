import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../api"; // Asegúrate de que la ruta es correcta.
import {
  FETCH_JOB_BY_ID,
} from "./actionTypes";
import { fetchJobByIdSuccess, fetchJobByIdError } from "./actions";

// La URL base de tu API
const BASE_URL = process.env.REACT_APP_NEST_API;

function* fetchJob({ payload: id }) { // Destructurando para obtener `id` directamente

  try {
    const response = yield call(api.get, BASE_URL, `/job-list/${id}`); // Usa el `id` directamente aquí
    yield put(fetchJobByIdSuccess(response));
  } catch (error) {
    yield put(fetchJobByIdError(error.toString()));
  }
}

function* fetchJobSaga() {
  yield takeEvery(FETCH_JOB_BY_ID, fetchJob);
}

export default fetchJobSaga;
