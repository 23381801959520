import {
  FETCH_JOB_LIST_VIEW,
  FETCH_JOB_LIST_VIEW_SUCCESS,
  FETCH_JOB_LIST_VIEW_ERROR,
  SEARCH_JOB_BY_RUT,
  SEARCH_JOB_BY_RUT_SUCCESS,
  SEARCH_JOB_BY_RUT_ERROR,
} from "./actionTypes";

export const fetchJobListView = (page = 1, limit = 10) => ({
  type: FETCH_JOB_LIST_VIEW,
  payload: { page, limit },
});

export const fetchJobListViewSuccess = data => ({
  type: FETCH_JOB_LIST_VIEW_SUCCESS,
  payload: {
    data: data.data,
    totalPages: data.totalPages,
    total: data.total,
    page: data.page,
    limit: data.limit,
  },
});

export const fetchJobListViewError = error => ({
  type: FETCH_JOB_LIST_VIEW_ERROR,
  payload: error,
});

export const searchJobByRut = (rut, page = 1, limit = 10) => ({
  type: SEARCH_JOB_BY_RUT,
  payload: { rut, page, limit },
});

export const searchJobByRutSuccess = data => ({
  type: SEARCH_JOB_BY_RUT_SUCCESS,
  payload: {
    data: data.data,
    totalPages: data.totalPages,
    total: data.total,
    page: data.page,
    limit: data.limit,
  },
});

export const searchJobByRutError = error => ({
  type: SEARCH_JOB_BY_RUT_ERROR,
  payload: error,
});
