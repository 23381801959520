import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  getAllUsers,
  deleteUser,
  resetUserFlag,
} from "store/users/actions";
import AdministrationTemplate from "ui/templates/Administration";

const AdministrationContainer = () => {
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    userName: "",
    name: "",
    lastName: "",
    email: "",
    password: "",
    userLevel: "",
    campaigns: [],
  });

  const resetUserData = () => {
    setUserData({
      userName: "",
      name: "",
      lastName: "",
      email: "",
      password: "",
      userLevel: "",
      campaigns: [],
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState({ name: "", id: "" });

  const usersData = useSelector((state) => state?.AllUsers?.data || []);

  const {
    loading: createUserLoading,
    success: createUserSuccess,
    message: createUserErrorMessages,
  } = useSelector((state) => state?.CreateUser || {});

  const {
    success: deleteUserSuccess,
    error: deleteUserErrorMessages,
  } = useSelector((state) => state?.DeleteUser || {});

  const [localUsersData, setLocalUsersData] = useState(usersData);

  useEffect(() => {
    setLocalUsersData(usersData);
  }, [usersData]);

  const userList = localUsersData.map((user) => ({
    id: user.id,
    userName: user.userName,
    fullName: `${user.name} ${user.lastName}`,
    name: user.name,
    lastName: user.lastName,
    email: user.email,
    role: { 1: "Administrator", 2: "Supervisor", 3: "Analyst" , 4: "Coach" }[user.userLevel], // TODO: get roles from database
    campaigns: user.campaigns,
  }));

  const handleOpenModal = (type, id = null) => {
    setOpenModal(true);
    setModalType({ name: type, id: id });
    dispatch(resetUserFlag());
    // resetUserData()
  };

  const handleOnChangeForm = (event) => {
    const { name, value } = event.target;

    setUserData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleOnClickAddUser = (userData) => {
    dispatch(createUser(userData));
  };

  const handleOnClickDeleteUser = (id) => {
    dispatch(deleteUser(id));
    setOpenModal(false);
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (createUserSuccess && userData.userName && userData.name && userData.lastName && userData.email && userData.password) {
      setLocalUsersData((prevUsers) => [...prevUsers, userData]);
      setOpenModal(false);
      resetUserData();
    }
  }, [createUserSuccess, userData]);

  useEffect(() => {
    if (deleteUserSuccess) {
      setLocalUsersData((prevUsers) => prevUsers.filter(user => user.id !== modalType.id));
    }
  }, [deleteUserSuccess, modalType.id]);

  return (
    <AdministrationTemplate
      userData={userData}
      userList={userList}
      handleOnChangeForm={handleOnChangeForm}
      handleOnClickAddUser={handleOnClickAddUser}
      handleOnClickDeleteUser={handleOnClickDeleteUser}
      openModal={openModal}
      setOpenModal={setOpenModal}
      setModalType={setModalType}
      handleOpenModal={handleOpenModal}
      modalType={modalType}
      createUserErrorMessages={createUserErrorMessages}
      createUserLoading={createUserLoading}
      deleteUserErrorMessages={deleteUserErrorMessages}
    />
  );
};

export default AdministrationContainer;
